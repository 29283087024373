import React from "react";

const Intro = (props) => {

    return (
        <p className={props.classes ? props.classes : 'mb-12 max-w-xl text-center text-xl container md:mb-16 md:text-2xl'}
           dangerouslySetInnerHTML={{ __html: props.text }}></p>
    )
};

export default Intro;
