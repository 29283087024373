import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Masthead from "../components/masthead";
import Intro from "../components/intro";
import ServicesGrid from "../components/services-grid";
import BannerText from "../components/banner-text";

const ServicePage = ({data}) => {

    const contact = {
        email: data.site.siteMetadata.email,
        tel: data.site.siteMetadata.telephone,
        mob: data.site.siteMetadata.mobile
    };

    return (
        <>
            <Layout>
                <SEO title="Services"/>
                <Masthead title="Services" image={data.background.childImageSharp.fluid}/>
                <Intro text="Explore our diverse range of modern and traditional wall rendering services."/>
                <ServicesGrid/>
                <BannerText title="Request a quote"
                            summary={`Call our office <a href="tel:${contact.tel.replace(/\s+/g, '')}">${contact.tel}</a>, mobile <a href="tel:${contact.mob.replace(/\s+/g, '')}">${contact.mob}</a> or email <a href="mailto:${contact.email}?subject=Enquiry">${contact.email}</a>`}
                            background={data.backgroundPhones.childImageSharp.fluid}
                            arrowPosition="none"/>
            </Layout>
        </>
    )
}

export default ServicePage

export const query = graphql`
    query ServicePageQuery {
        site {
            siteMetadata {
                email
                telephone
                mobile
            }
        }
        background: file(absolutePath: {glob: "**/content/services/traditional-rendering/images/08.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1600, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        backgroundPhones: file(relativePath: {eq: "background-phones.png"}) {
            childImageSharp {
                fluid(maxWidth: 1600, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
